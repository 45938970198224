import "./App.css";
import AbodeMainLandingPage from "./LandingPage/AbodeMainLandingPage";

function App() {
  return (
    <>
      <AbodeMainLandingPage />
    </>
  );
}

export default App;
