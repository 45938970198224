import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';  // Import for Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyD1158T5ePsYgnu8kh7Tw_uok4-2aSCmPw",
    authDomain: "sterling-abode.firebaseapp.com",
    projectId: "sterling-abode",
    storageBucket: "sterling-abode.appspot.com",
    messagingSenderId: "52171858213",
    appId: "1:52171858213:web:6fa04122712c33eb4dbacc",
    measurementId: "G-RS0H3PEQ7P"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the database
const db = getFirestore(app);

export default db;